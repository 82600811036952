* {
  margin: 0;
  padding: 0;
  font-family: "jost", sans-serif;
}

.loading-screen {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  padding-top: 20%;
}

.circle {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: white;
  border-radius: 50%;
  bottom: -96px;
  text-align: center;
  padding: 5px;
}

.ReactFlagsSelect-module_selectBtn__19wW7::after {
  display: none !important;
}

.formHeight {
  height: 62px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  border-radius: 4px;
}

input[type="number"]::placeholder {
  background-image: url("../Images/icon-right-arrow.png");
  background-size: 100% 100%;
}

p.error {
  margin: 1% 0 1% 0;
  color: red;
  width: 100%;
  text-align: left;
  font-size: 14px;
  padding-left: 2.4%;
  position: absolute;
}

.pink {
  color: #c97b5a;
  font-weight: 600;
  font-size: 24px;
}

input[type="text"]::placeholder {
  background-image: url("../Images/icon-right-arrow.png");
  background-size: 100%;
}

input.otp-field {
  width: 94px !important;
  height: 88px;
  border: 1px solid #80a03c;
  border-radius: 5px;
}

.otp-box {
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 10px;
}

.otp-error {
  border: 1px solid red !important;
}

.otp-focus {
  border: 2px solid !important;
}

.main {
  position: relative;
  /* background-color: aqua; */
  /* position: relative; */
  height: 540px;
  z-index: 0;
  overflow: hidden;
  margin-bottom: 62px;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(128, 160, 60, 1) !important;
}

.main .page-heading-otp {
  /* position: absolute; */
  width: 100%;
  margin-bottom: 2%;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  z-index: 200;
}

.main .background-box {
  /* position: relative; */
  width: 100%;
  height: 100%;
  background-image: url("https://store-5h8rqg02f8.mybigcommerce.com/content/otp-login/Images/image-background-desktop.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 2%;
}

.main .background-box .box {
  width: 647px;
  height: 318px;
  background-color: white;
  /* left: 30%; */
  border-radius: 8px;
  padding-top: 20px;
  padding-left: 1% !important;
  padding-right: 1% !important;
}

.main .background-box .box .ReactFlagsSelect-module_selectBtn__19wW7 {
  border: 1.5px solid rgba(128, 160, 60, 1);
  height: 62px;
  width: 90%;
  margin: 0 5%;
  content: "" !important;
  /* -webkit-appearance: none; */
}

.MuiLoadingButton-root {
  width: 85% !important;
  height: 85% !important;
  border-radius: 100px 100px 100px 100px !important;
  bottom: -7% !important;
  background-color: rgba(128, 160, 60, 1);
}

p.heading-page {
  font-style: normal;
  /* font-weight: 600; */
  font-size: 20px;
  line-height: 120%;
  text-align: center !important;
  margin-bottom: 35px;
}
input[type="text"] {
  /* border: 1.5px solid rgba(128, 160, 60, 1); */
  font-size: 100%;
  font-weight: 400;
  border: 0;
}

input[type="email"] {
  /* border: 1.5px solid rgba(128, 160, 60, 1); */
  height: 100%;
  border: 0;
  font-size: 18px;
  font-weight: 400;
}

.css-1x5jdmq {
  border-radius: 8px;
  height: 50px !important;
  width: 88px !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border: 1.5px solid rgba(128, 160, 60, 1);
}

.MuiOtpInput-TextField-1,
.MuiOtpInput-TextField-2,
.MuiOtpInput-TextField-3,
.MuiOtpInput-TextField-4,
.MuiOtpInput-TextField-5,
.MuiOtpInput-TextField-6 {
  height: 100% !important;
}

.ReactFlagsSelect-module_disabledBtn__3A4GF {
  background-color: white !important;
}

body {
  padding-top: 5% !important;
  background: white !important;
}

.header .header-search-suggestion ul {
  display: none !important;
}

.resend-otp-content {
  font-weight: 400;
  font-size: 20px;
  line-height: 125%;
}

.resend-otp {
  font-weight: 600;
  font-size: 24px;
  line-height: 125%;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (min-width: 350px) and (max-width: 750px) {
  .loading-screen {
    padding-top: 100%;
  }

  input.otp-field {
    width: 42px !important;
    height: 48px;
    /* row-gap: 8px; */
  }

  .otp-box {
    justify-content: space-around;
  }

  .formHeight {
    height: 57px !important;
  }

  .circle {
    bottom: -53px;
    height: 80px;
    width: 80px;
    padding: 0;
    margin-top: 1%;
  }

  .css-1x5jdmq {
    height: 48px !important;
    width: 42px !important;
  }

  .MuiOtpInput-Box {
    height: 52px !important;
  }

  p.error {
    font-size: 10px;
    width: 35%;
  }

  .main {
    position: relative;
    /* background-color: aqua; */
    /* position: relative; */
    height: 100%;
  }

  .main .page-heading-otp {
    font-size: 24px;
    top: 0%;
  }

  .pink {
    font-size: 19.2px;
  }

  p.heading-page {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 1%;
  }

  .main .background-box {
    background-image: url("https://store-5h8rqg02f8.mybigcommerce.com/content/otp-login/Images/image-background-mobile.png");
    height: 391px;
    top: 0%;
  }

  .main .background-box .box {
    width: 90%;
    height: 246px;
    background-color: white;
    top: 10%;
    padding-top: 4%;
  }

  .main .background-box .box .ReactFlagsSelect-module_selectBtn__19wW7 {
    height: 42px;
  }

  .MuiInputBase-root {
    height: 42px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  input[type="number"],
  input[type="email"] {
    padding: 0 0 !important;
  }

  .MuiBox-root {
    gap: 8px !important;
    height: 48px;
  }

  .MuiInputBase-formControl {
    /* width: 42px; */
    height: 48px;
  }

  .resend-otp-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
  }

  .resend-otp {
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    text-decoration: underline;
    cursor: pointer;
  }

  .MuiLoadingButton-root {
    width: 85% !important;
    height: 85% !important;
    border-radius: 50% !important;
    bottom: -6% !important;
  }

  .MuiLoadingButton-root img {
    width: 80%;
  }

  .static-content {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr);
    text-align: center !important;
    justify-items: center;
  }

  .static-content .kapiva-app-detail {
    width: 100% !important;
    text-align: center !important;
    margin: 0 !important;
  }

  .static-content hr {
    height: auto !important ;
    margin: 5% 0;
    width: 90%;
  }

  .static-content .kapiva-app-offers {
    width: 100% !important;
    text-align: center;
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 751px) and (max-width: 900px) {
  .main .background-box {
    background-image: url("https://store-5h8rqg02f8.mybigcommerce.com/content/otp-login/Images/image-background-tab.png");
  }

  .main .background-box .box {
    width: 60%;
    top: 25%;
  }

  p.heading-page {
    margin-bottom: 0px;
  }
}

/**************** Integration *****************/

.container {
  max-width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.page-heading {
  display: none !important;
}

.page-content {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

ol.breadcrumbs {
  display: none !important;
}

p {
  margin: 0;
}
